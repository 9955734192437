import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FirstPageComponent } from "./first-page/first-page.component";
import { CartComponent } from "./cart/cart.component";
import { CheckoutPageComponent } from "./checkout-page/checkout-page.component";
import { TermsAndConditionComponent } from "./terms-and-condition/terms-and-condition.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";


const routes: Routes = [
    { path: '',   redirectTo: 'firstPage', pathMatch: 'full' },
    { path: 'firstPage', component: FirstPageComponent },
    { path: 'cart',        component: CartComponent },
    { path: 'checkout',        component: CheckoutPageComponent },
    { path: 'termsAndServices',        component: TermsAndConditionComponent },
    { path: 'contactUs',        component: ContactUsComponent },
    { path: '**', component: FirstPageComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
