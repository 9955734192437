<mat-dialog-content>
  <div class="modal-content">
    <div class="modal-container-failed">
      <span class="float-right m-2 close-icon" (click)="closeMe()"><i class="fad fa-times"></i></span>
      <div class="modal-body">
                <span class="">
                    <i class="fal fa-times-circle fa-7x modal-icon-failed"></i>
                  <!--<i class="fal fa-times-circle fa-7x modal-icon-failed"></i>-->
                </span>
        <span class="body-bold"> Oops! </span>
        <span class="body-regular" *ngIf="data.code !== '402' && data.errorCode !== '409'">
                    We are experiencing technical difficulties, please email *EngageLATAM@Turner.com so we can investigate this issue.
                </span>
        <span class="body-regular" *ngIf="data.code === '402'">
                    We were not able to create this audience/definition. Please review your selections and try again.
                </span>
        <span class="body-regular" *ngIf="data.code === '409'">
                    This audience/campaign name has already been used, please change the name and try again.
                </span>
        <span class="body-regular" *ngIf="showDetails && data.code"> {{ 'Error Code: ' + data.code  }}</span>
        <span class="body-regular" *ngIf="showDetails && data.message"> {{ 'Error Message:  ' + data.message }}</span>
        <span class="body-regular" *ngIf="showDetails && data.error "> {{ 'Error Status:  ' + data.status }}</span>
      </div>
      <div class="section-footer-failed">
        <div class="section-left-failed" (click)="closeMe()">
                    <span class="footer-text">
                        Close
                    </span>
        </div>
        <div class="section-right" (click)="showDetail()">
                    <span class="footer-text" *ngIf="!showDetails">
                        Show Details
                    </span>
          <span class="footer-text" *ngIf="showDetails">
                        Hide Details
                    </span>
        </div>
      </div>

    </div>
  </div>
</mat-dialog-content>
