import { Injectable } from '@angular/core';
import { MessageComponent } from '../../shared-components/message/message.component';
import { ApiService } from '../api/api.service';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { interval, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ApiSignaturesService {

  chargeCardOnceCalledRecently = false;
  getTheCurrentUserOnceCalledRecently = false;
  saveTransactionInfoCalledRecently = false;
  chargeCardOnce: any;
  getTheCurrentUser: any;
  saveTransactionInfo: any;
  intervalTimer = interval(10000);
  subscription;
  constructor(
    private apiService: ApiService,
    private dialog: MatDialog
  ) {
      this.subscription = this.intervalTimer.subscribe(() => {
        this.chargeCardOnceCalledRecently = false;
        this.getTheCurrentUserOnceCalledRecently = false;
        this.saveTransactionInfoCalledRecently = false;
      });

      this.chargeCardOnce = new Subject();
      this.getTheCurrentUser = new Subject();
      this.saveTransactionInfo = new Subject();
  }


  chargeCard(options) {
    if (!this.chargeCardOnceCalledRecently) {
      this.chargeCardOnceCalledRecently = true;
      const params = {
        url: 'purchase/one_time_card_charge',
        method: 'POST',
        body: options
      }

      this.apiService.callApi(params).pipe(take(1)).subscribe(
        (result) => {
          this.chargeCardOnce.next(result);
        },
        (error) => {
          this.dialog.open(MessageComponent, {
            data: {
              detail: 'error '
            }
          });
        }
      );
    }
  }

  callAndSaveTransactionInfo(options) {
    if (!this.saveTransactionInfoCalledRecently) {
      this.saveTransactionInfoCalledRecently = true;
      const params = {
        url: 'transaction/save_transaction_detail/',
        method: 'POST',
        body: options
      }

      this.apiService.callApi(params).pipe(take(1)).subscribe(
        (result) => {
          this.saveTransactionInfo.next(result);
        },
        (error) => {
          this.dialog.open(MessageComponent, {
            data: {
              detail: 'error '
            }
          });
        }
      );
    }
  }

  getTheUser() {
    if (!this.getTheCurrentUserOnceCalledRecently) {
      this.getTheCurrentUserOnceCalledRecently = true;
      const params = {
        url: 'user/get_user',
        method: 'GET',
      }

      this.apiService.callApi(params).pipe(take(1)).subscribe(
        (result) => {
          this.getTheCurrentUser.next(result);
        },
        (error) => {
          this.dialog.open(MessageComponent, {
            data: {
              detail: 'error '
            }
          });
        }
      );
    }
  }

}
