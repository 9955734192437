import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'querystring';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) {}

  callApi(data: any): Observable<any> {
    let url = `${environment.apiUrl}/${data.url}`;
    if (data.query) {
      url += `?${stringify(data.query)}`;
    }
    switch (data.method) {
      case 'GET': {
        return this.http.get(url);
      }
      case 'POST': {
        return this.http.post(url, data.body);
      }
      case 'DELETE': {
        return this.http.delete(url);
      }
      case 'PATCH': {
        return this.http.patch(url, data.body);
      }
    }

  }

  purchaseOneTime() {

  }
}
