import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { CartModalComponent } from '../cart-modal/cart-modal.component';
import { RestService } from '../rest.service';
import { CartModel } from '../interfaces/cart-model';
import { ApiService } from '../services/api/api.service';
import { MessageComponent } from '../shared-components/message/message.component';
import { ApiSignaturesService } from "../services/api-signatures/api-signatures.service";

@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss']
})
export class CheckoutPageComponent implements OnInit {

  checkoutForm: FormGroup;
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  phoneNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  creditCardNumber: string;
  expDate: string;
  csvNumber: string;
  options: {};
  data: any;
  cart: CartModel;

  constructor(private fb: FormBuilder,
              private router: Router,
              private apiService: ApiService,
              private restService: RestService,
              private apiSignaturesService: ApiSignaturesService,
              public dialog: MatDialog) {
    this.checkoutForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(1)]],
      lastName: ['', [Validators.required, Validators.minLength(1)]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.minLength(10)]],
      confirmEmail: ['', [Validators.required, Validators.email]],
      address1: ['', [Validators.required, Validators.minLength(1)]],
      address2: [''],
      city: ['', [Validators.required, Validators.minLength(1)]],
      state: ['', [Validators.required, Validators.minLength(2)]],
      country: ['', [Validators.required, Validators.minLength(2)]],
      zipCode: ['', [Validators.required, Validators.minLength(5)]],
      creditCardNumber: ['', [Validators.required, Validators.minLength(5)]],
      expDate: ['', [Validators.required, Validators.minLength(5)]],
      csvNumber: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  ngOnInit() {
  }

  getEmailErrorMessage() {
    if (this.checkoutForm.controls.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.checkoutForm.controls.email.hasError('email') ? 'Not a valid email' : '';
  }

  getConfirmEmailErrorMessage() {
    if (this.checkoutForm.controls.confirmEmail.hasError('required')) {
      return 'You must enter a value';
    }

    return this.checkoutForm.controls.confirmEmail.hasError('email') ? 'Not a valid email' : '';
  }

  returnToCart() {
    this.router.navigateByUrl('/cart');
  }

  placeTheOrder(data) {
    this.options = {
      email: this.checkoutForm.value.email,
      user_name: this.checkoutForm.value.email,
      admin_level: '0',
      first_name: this.checkoutForm.value.firstName,
      last_name: this.checkoutForm.value.lastName,
      date_of_birth: '',
      address: this.checkoutForm.value.address1,
      phone: this.checkoutForm.value.phone,
      business_name: '',
      business_address: '',
      modified_at: '',
      city: this.checkoutForm.value.city,
      address_2: this.checkoutForm.value.address2,
      business_address_2: '',
      business_city: '',
      business_state: '',
      business_zipcode: '',
      zipcode: this.checkoutForm.value.zipcode,
      home_state: this.checkoutForm.value.state,
      ccnumber: this.checkoutForm.value.creditCardNumber,
      ccexp: this.checkoutForm.value.expDate,
      csv: this.checkoutForm.value.expDate,
      amount: localStorage.getItem('CartTotal')
    };

    const chargeCard = {
      ccnumber: this.checkoutForm.value.creditCardNumber,
      ccexp: this.checkoutForm.value.csvNumber,
      amount: localStorage.getItem('CartTotal')
    }

    const info = {
      cc_number: this.checkoutForm.value.creditCardNumber,
      cc_csv: this.checkoutForm.value.csvNumber,
      cc_exp: this.checkoutForm.value.expDate,
      amount: localStorage.getItem('CartTotal'),
      description: '',
      email: this.checkoutForm.value.email,
    }

    this.apiSignaturesService.chargeCardOnce.subscribe(
      (result) => {
        console.log(result);
        this.apiSignaturesService.saveTransactionInfo.subscribe(
          (res) => {
            console.log(res);
          },
          (err) => {
            this.dialog.open(MessageComponent, {
              data: {
                detail: err
              }
            });
          }
        );
        this.apiSignaturesService.callAndSaveTransactionInfo(info);
      },
      (error) => {
        this.dialog.open(MessageComponent, {
          data: {
            detail: error
          }
        });
      }
    );
    this.apiSignaturesService.chargeCard(chargeCard);
  }
}
