import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, ObservableInput, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from './../auth/auth.service';

@Injectable()

export class InterceptorService implements HttpInterceptor {

  constructor(public auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // intercept api calls and add the token
    if (request.url.startsWith(environment.apiUrl)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((errResponse): ObservableInput<any> => {
        // if an api call comes back with status 403 "unauthorized", logout
        if (errResponse.url.startsWith(environment.apiUrl) && errResponse.status === 403) {
          this.auth.logout();
        }
        return throwError(errResponse);
      })
    );
  }
}
