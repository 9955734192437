import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.scss']
})
export class TermsAndConditionComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  returnToFirstPage() {
    this.router.navigateByUrl('/firstPage')
  }
}
