import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "../common.service";
import { CartModel } from "../interfaces/cart-model";
import { Auth } from 'aws-amplify'


@Component({
  selector: 'app-first-page',
  templateUrl: './first-page.component.html',
  styleUrls: ['./first-page.component.scss']
})
export class FirstPageComponent implements OnInit {

  cart: CartModel;
  toVerifyEmail: boolean = false;
  signstatus: string = '';
  user = {
    username: 'musavi1',
    password: 'Ruby@123'
  }
  newUser = {
    username: 'musavi.one@gmail.com',
    password: 'Ruby@123',
    attributes: {
      email: 'musavi.one@gmail.com'
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.cart = {
      mainPlan: 'Please select a plan',
      discountedPrice: 0,
      originalPrice: 0,
      numberOfEmailAddresses: 1,
    }

    Auth.configure({
      userPoolId: 'us-east-1_E6yPURhtK',
      userPoolWebClientId: '67h2l34kdai6th036qvgbombqc',
      oauth: {
        region: 'us-east-1',
        domain: 'goldenpenguintech.auth.us-east-1.amazoncognito.com',
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://127.0.0.1:8000',
        redirectSignOut: 'https://127.0.0.1:8000',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
    })
    this.createNewUser(this.newUser);
    this.cognitoSignIn(this.user);
  }

  showLogin() {
    `https://goldenpenguintech/login?response_type=code&client_id='6p0bauvbeb89tm5m6eprvpnjv7'&redirect_uri='http://localhost:4200'`
  }

  createNewUser(user) {
    Auth.signUp(user)
      .then(data => {
        console.log(data);
        this.toVerifyEmail = true;
        this.signstatus = '';
      })
      .catch(err => console.log(err));
  }

  verifyEmail(userName, verifycode) {
    Auth.confirmSignUp(userName, verifycode,
      {forceAliasCreation: true}).then(data => {
      console.log(data)
      this.toVerifyEmail = false;
      this.signstatus = 'signin'
    })
      .catch(err => console.log(err));
  }

  cognitoSignIn(user) {
    Auth.signIn(user).then(user => {
      // sets jwt to localstorage
      localStorage.setItem('jwt', user.signInUserSession.accessToken.jwtToken)
    })
      .catch(err => console.log(err));
  }

  cognitoSignOut() {
    Auth.signOut()
      .then(data => console.log(data))
      .catch(err => console.log(err));
  }

  addToCart(plan) {
    if (!plan) {
      return;
    }
    this.cart.mainPlan = plan;
    switch (plan) {
      case 'Individual':
        this.cart.discountedPrice = 1.99;
        this.cart.originalPrice = 4.99;
        this.cart.numberOfEmailAddresses = 1;
        this.cart.maxNumberOfEmailAddresses = 1;
        break;
      case 'Basic':
        this.cart.discountedPrice = 4.99;
        this.cart.originalPrice = 12.99;
        this.cart.numberOfEmailAddresses = 3;
        this.cart.maxNumberOfEmailAddresses = 3;
        break;
      case 'Business':
        this.cart.discountedPrice = 14.99;
        this.cart.originalPrice = 39.99;
        this.cart.numberOfEmailAddresses = 10;
        this.cart.maxNumberOfEmailAddresses = 10;
        break;
      case 'Premium':
        this.cart.discountedPrice = 1.99;
        this.cart.originalPrice = 3.99;
        this.cart.numberOfEmailAddresses = 11;
        this.cart.maxNumberOfEmailAddresses = 100000;
        break;
    }
    this.commonService.selectedCart(this.cart);
    this.router.navigateByUrl('/cart');
  };
}
