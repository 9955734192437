<div class="checkout-main card ux-card p-2">
  <div class="card us-card">
    <form [formGroup]="checkoutForm" class="p-5">
      <div class="row pt-2 pl-3">
        <h6>Credit Card information</h6>
      </div>
      <div class="row pl-3">
        <img
          src="https://www.merchantequip.com/image/?logos=v|m|a|d&height=32"
          alt="Merchant Equipment Store Credit Card Logos"/>
      </div>
      <div class="row">
        <div class="col-5">
          <mat-form-field>
            <mat-label>Credit Card Number</mat-label>
            <input matInput
                   formControlName="creditCardNumber"
                   placeholder="Credit Card Number"
                   [(ngModel)]='creditCardNumber'
                   required>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field>
            <mat-label>Expiration Date</mat-label>
            <input matInput
                   formControlName="expDate"
                   placeholder="Expiration Date"
                   [(ngModel)]='expDate'
                   required>
          </mat-form-field>
        </div>
        <div class="col-3">
          <mat-form-field>
            <mat-label>CSV Number</mat-label>
            <input matInput
                   formControlName="csvNumber"
                   placeholder="CSV number"
                   [(ngModel)]='csvNumber'
                   required>
          </mat-form-field>
        </div>
      </div>
      <div class="row pt-2 pl-3">
        <h6>Customer information:</h6>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>First name</mat-label>
            <input matInput
                   formControlName="firstName"
                   placeholder="First Name"
                   [(ngModel)]='firstName'
                   required>
            <mat-error *ngIf="false">{{'Please enter a valid first name'}}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input matInput
                   formControlName="lastName"
                   placeholder="Last name"
                   [(ngModel)]='lastName'
                   required>
            <mat-error *ngIf="false">{{'Please enter a valid last name'}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Email address</mat-label>
            <input matInput
                   formControlName="email"
                   placeholder="Email address"
                   [(ngModel)]='email'
                   required>
            <mat-error *ngIf="false">{{getEmailErrorMessage()}}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Phone number</mat-label>
            <input matInput
                   formControlName="phoneNumber"
                   placeholder="Phone number"
                   [(ngModel)]='phoneNumber'
                   required>
            <mat-error *ngIf="false">{{ getEmailErrorMessage() }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Confirm email address</mat-label>
            <input matInput
                   formControlName="confirmEmail"
                   placeholder="Email address"
                   [(ngModel)]='confirmEmail' >
            <mat-error *ngIf="false">{{ getConfirmEmailErrorMessage() }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-6">

        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field>
            <mat-label>Address 1</mat-label>
            <input matInput
                   formControlName="address1"
                   placeholder="Address1"
                   [(ngModel)]='address1'
                   required>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field>
            <mat-label>Address 2</mat-label>
            <input matInput
                   formControlName="address2"
                   placeholder="Address2"
                   [(ngModel)]='address2' >
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>City</mat-label>
            <input matInput
                   formControlName="city"
                   placeholder="City"
                   [(ngModel)]='city'
                   required>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>State</mat-label>
            <input matInput
                   formControlName="state"
                   placeholder="State"
                   [(ngModel)]='state'
                   required>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field>
            <mat-label>Country</mat-label>
            <input matInput
                   formControlName="country"
                   placeholder="Country"
                   [(ngModel)]='country'
                   required>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field>
            <mat-label>Zip code</mat-label>
            <input matInput
                   formControlName="zipCode"
                   placeholder="Zip Code"
                   [(ngModel)]='zipCode' >
          </mat-form-field>
        </div>
      </div>

      <div class="row pt-5">
        <div class="col-6">
          <button type="button"
                  class="btn btn-cancel btn-block"
                  (click)="returnToCart()">
            Return to Cart
          </button>
        </div>
        <div class="col-6">
          <button type="button"
                  class="btn btn-warning btn-block"
                  (click)="placeTheOrder('Purchase')">
            Place Order
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
