/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./footer.component";
import * as i3 from "@angular/router";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "row footer-background h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 14, "div", [["class", "col-lg-12 col-md-12 col-sm-12 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "footer-text align-tob"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Copyright \u00A9 2019 - 2020 Golden Penguin Technology, LLC. All Rights Reserved. "])), (_l()(), i1.ɵeld(5, 0, null, null, 7, "span", [["class", "footer-text align-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Use of this Site is subject to express terms of use. By using this site, you signify that you agree to be bound by "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["href", "/termsAndServices"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToTermsAndServices() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" terms and condition "])), (_l()(), i1.ɵted(-1, null, [". Click "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["href", "/termsAndServices"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToTermsAndServices() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" here "])), (_l()(), i1.ɵted(-1, null, [" to read detail. "])), (_l()(), i1.ɵeld(13, 0, null, null, 3, "span", [["class", "footer-text align-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" For any question or concern please "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "a", [["href", "/contactUs"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.contactUs() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Contact US"]))], null, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i2.FooterComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i2.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
