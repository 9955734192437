import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { FirstPageComponent } from './first-page/first-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CartComponent } from './cart/cart.component';
import { RouterModule, Routes } from "@angular/router";
import { CounterComponent } from './counter/counter.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CartModalComponent } from './cart-modal/cart-modal.component';
import { OverlayModule } from "@angular/cdk/overlay";
import { MatDialogModule } from "@angular/material/dialog";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatButtonModule, MatCheckboxModule, MatDatepickerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule, MatMenuModule, MatNativeDateModule, MatProgressBarModule,
  MatRippleModule, MatSelectModule, MatTooltipModule,
  MatTreeModule
} from "@angular/material";
import { CheckoutPageComponent } from './checkout-page/checkout-page.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { MessageComponent } from './shared-components/message/message.component';
import { AuthService } from "./services/auth/auth.service";
import { InterceptorService } from "./services/interceptor/interceptor.service";
import { CdkTreeModule } from "@angular/cdk/tree";

const routes: Routes = [
  { path: 'firstPage', component: FirstPageComponent },
  { path: 'cart',        component: CartComponent },
  { path: '',   redirectTo: '/', pathMatch: 'full' },
  { path: '**', component: CartComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    FirstPageComponent,
    CartModalComponent,
    HeaderComponent,
    FooterComponent,
    CartComponent,
    CounterComponent,
    CheckoutPageComponent,
    TermsAndConditionComponent,
    ContactUsComponent,
    MessageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    OverlayModule,
    HttpClientModule,
    FontAwesomeModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    FormsModule,
    CdkTreeModule,
    MatTreeModule,
    MatIconModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatMenuModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatDialogModule
  ],
  entryComponents: [
    CartModalComponent,
    MessageComponent
  ],
  providers: [
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
