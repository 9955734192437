<div class="">
  <div class="input-group">
    <div class="input-group-prepend">
      <button class="btn btn-dark btn-sm" id="minus-btn" (click)="subtractUser()"><fa-icon [icon]="faMinus"></fa-icon></button>
    </div>
    <input type="number" id="qty_input" class="form-control form-control-sm" value="{{ num }}" min="1">
    <div class="input-group-prepend">
      <button class="btn btn-dark btn-sm" id="plus-btn" (click)="addUser()"><fa-icon [icon]="faPlus"></fa-icon></button>
    </div>
  </div>
</div>
