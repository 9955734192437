<div class="container-fluid">
  <div class="pt-5">
    <button type="button" class="btn btn-warning btn-block" (click)="returnToFirstPage()">Return</button>
  </div>
  <div class="p-5">
    <div class="card ux-card p-5">
      <h2>
        1. OVERVIEW
      </h2>

      <p>
        This Universal Terms of Service Agreement (this “Agreement”) is entered into by and between GoldenPenguinTech.com (Golden Penguin Technology) and you, and is made effective as of the date of your use of this website ("Site") or the date of electronic acceptance. This Agreement sets forth the general terms and conditions of your use of the Site and the products and services purchased or accessed through this Site (individually and collectively, the “Services”). Services Agreements and additional policies apply to certain Services and are in addition to (not in lieu of) this Agreement. In the event of a conflict between the provisions of a Services Agreement and the provisions of this Agreement, the provisions of the applicable Services Agreement shall control.

        The terms “we”, “us” or “our” shall refer to Golden Penguin Technology. The terms “you”, “your”, “User” or “customer” shall refer to any individual or entity who accepts this Agreement, has access to your account or uses the Services. Nothing in this Agreement shall be deemed to confer any third-party rights or benefits.
      </p>

      <h2>
        2. REFUND POLICY
      </h2>
      <p>
        Products purchased from GoldenPenguinTech.com may be refunded only if cancelled within the refund period specified below in this policy.
      </p>
      <p>
        “Date of the transaction”, for the purpose of this Refund Policy, means the date of purchase of any product or service, which includes the date any renewal is processed by GoldenPenguinTech.com in accordance with the terms and conditions of the applicable product or service agreement.
      </p>
      <p>
        "Refund Period", you may cancel a product at any time, but a refund will only be issued if you request a refund with GoldenPenguinTech's customer service within the refund time frame specified for the applicable product, if available at all.
      </p>
      <p>
        No Refund After Account Closure. If eligible for a refund, it is necessary for you to request a refund prior to account closure. You may elect to close your account with us at any time, but upon account closure you will no longer be eligible for a refund as otherwise permitted under this Refund Policy.
      </p>
      <p>
        International CUSTOMERS: ANY AND ALL PRODUCTS PURCHASED FROM GoldenPenguinTech MAY BE REFUNDED ONLY IF CANCELLED WITHIN SEVEN (7) DAYS FROM THE DATE OF THE TRANSACTION.
      </p>
      <div>
        <span>
          <h3>
            Standard Refund Terms
          </h3>
          <h6>
            Annual Plans+ - Within 30 days of the date of the transaction.
          </h6>
          <h6>
            Monthly Plans*+ - Within 48 hours of the date of the transaction.
          </h6>
        </span>
        <div>
          <p>
            *Monthly Plans include all plans with less than a 1-year term (e.g., 6 mos., 9 mos., etc.)
          </p>
          <p>
            +Security products with remediation service cannot be refunded once a cleanup request has been submitted._
          </p>
        </div>
      </div>
      <div>
        <h2>
          3. ELIGIBILITY; AUTHORITY
        </h2>
        <p>
          This Site and the Services are available only to individuals or entities (“Users”) who can form legally binding contracts under applicable law. By using this Site or the Services, you represent and warrant that you are (i) at least eighteen (18) years of age, (ii) otherwise recognized as being able to form legally binding contracts under applicable law, or (iii) are not a person barred from purchasing or receiving the Services found under the laws of the United States or other applicable jurisdiction.
        </p>
        <p>
          If you are entering into this Agreement on behalf of a corporate entity, you represent and warrant that you have the legal authority to bind such corporate entity to the terms and conditions contained in this Agreement, in which case the terms "you", "your", "User" or "customer" shall refer to such corporate entity. If, after your electronic acceptance of this Agreement, Golden Penguin Technology finds that you do not have the legal authority to bind such corporate entity, you will be personally responsible for the obligations contained in this Agreement, including, but not limited to, the payment obligations. Golden Penguin Technology shall not be liable for any loss or damage resulting from Golden Penguin Technology’s reliance on any instruction, notice, document or communication reasonably believed by Golden Penguin Technology to be genuine and originating from an authorized representative of your corporate entity. If there is reasonable doubt about the authenticity of any such instruction, notice, document or communication, Golden Penguin Technology reserves the right (but undertakes no duty) to require additional authentication from you. You further agree to be bound by the terms of this Agreement for transactions entered into by you, anyone acting as your agent and anyone who uses your account or the Services, whether or not authorized by you.
        </p>
      </div>
      <div>
        <h2>
          4. MODIFICATION OF AGREEMENT, SITE OR SERVICES
        </h2>
        <p>
          Golden Penguin Technology may, in its sole and absolute discretion, change or modify this Agreement, and any policies or agreements which are incorporated herein, at any time, and such changes or modifications shall be effective immediately upon posting to this Site. Your use of this Site or the Services after such changes or modifications have been made shall constitute your acceptance of this Agreement as last revised. If you do not agree to be bound by this Agreement as last revised, do not use (or continue to use) this Site or the Services. In addition, Golden Penguin Technology may occasionally notify you of changes or modifications to this Agreement by email. It is therefore very important that you keep your shopper account (“Account”) information current. Golden Penguin Technology assumes no liability or responsibility for your failure to receive an email notification if such failure results from an inaccurate email address. In addition, Golden Penguin Technology may terminate Your use of Services for any violation or breach of any of the terms of this Agreement by You. GOLDEN PENGUIN TECHNOLOGY RESERVES THE RIGHT TO MODIFY, CHANGE, OR DISCONTINUE ANY ASPECT OF THIS SITE OR THE SERVICES, INCLUDING WITHOUT LIMITATION PRICES AND FEES FOR THE SAME, AT ANY TIME.
        </p>
      </div>
    </div>
  </div>
  <div class="pb-5">
    <button type="button" class="btn btn-warning btn-block" (click)="returnToFirstPage()">Return</button>
  </div>
</div>
