import { stringify } from 'querystring';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ApiService {
    constructor(http) {
        this.http = http;
    }
    callApi(data) {
        let url = `${environment.apiUrl}/${data.url}`;
        if (data.query) {
            url += `?${stringify(data.query)}`;
        }
        switch (data.method) {
            case 'GET': {
                return this.http.get(url);
            }
            case 'POST': {
                return this.http.post(url, data.body);
            }
            case 'DELETE': {
                return this.http.delete(url);
            }
            case 'PATCH': {
                return this.http.patch(url, data.body);
            }
        }
    }
    purchaseOneTime() {
    }
}
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
