import { HttpHeaders } from "@angular/common/http";
import { MatDialog } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/material/dialog";
import * as i3 from "./services/api/api.service";
var authorization;
const endpoint = 'https://connect.squareupsandbox.com/v2/payments';
const httpOptions = {
    headers: new HttpHeaders({
        'Square-Version': '2020-03-25',
        'Authorization': authorization,
        'Content-Type': 'application/json'
    })
};
export class RestService {
    constructor(httpClient, dialog, apiService) {
        this.httpClient = httpClient;
        this.dialog = dialog;
        this.apiService = apiService;
        this.options = {
            security_key: '6457Thfj624V5r7WUwc5v6a68Zsd6YEm',
            ccnumber: '4111111111111111',
            ccexp: '12/20',
            amount: '20'
        };
    }
    placeOrder(options) {
        if (options) {
            this.options = options;
        }
        return this.httpClient.post(endpoint, this.options);
    }
}
RestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RestService_Factory() { return new RestService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.MatDialog), i0.ɵɵinject(i3.ApiService)); }, token: RestService, providedIn: "root" });
