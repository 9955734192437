import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  showDetails = false;
  constructor(private  dialogRef: MatDialogRef<MessageComponent>,
              @Inject(MAT_DIALOG_DATA) public  data: any) {}

  ngOnInit(): void {
  }

  public  closeMe() {
    this.dialogRef.close();
  }

  public showDetail() {
    this.showDetails = !this.showDetails;
  }
}
