import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { MessageComponent } from './shared-components/message/message.component';
import { ApiService } from './services/api/api.service';
import { MatDialog, MatDialogRef } from '@angular/material';

var authorization;
const endpoint = 'https://connect.squareupsandbox.com/v2/payments';
const httpOptions = {
  headers: new HttpHeaders({
    'Square-Version': '2020-03-25',
    'Authorization': authorization,
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class RestService {
  options: any;
  constructor(
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private apiService: ApiService
  ) {
    this.options = {
      security_key: '6457Thfj624V5r7WUwc5v6a68Zsd6YEm',
      ccnumber: '4111111111111111',
      ccexp: '12/20',
      amount: '20'
    };
  }

  placeOrder(options): Observable<any> {
    if (options) {
      this.options = options;
    }
    return this.httpClient.post(endpoint , this.options);

  }
}
