import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class SharedVariableService {
    constructor() {
        this.globalVariable = [];
        this.sharedVariables = { key: '', value: '' };
        this.dropdownData = {
            "income": ["Low", "Middle", "High"],
            "gender": ["All", "Male", "Female"],
            "cableSubscriber": ["Total TV", "Pay TV"],
            "householdRole": ["All", "Head of Household", "Housewife"],
            "parentalStatus": ["All", "Has Children", "Does Not Have Children"],
            "age": { "min": 4, "max": 99 }
        };
        this.group = {
            groupName: 'Group 1',
            groupIndex: 0,
            operator: 'And',
            selectedLinks: [],
            isHidden: false
        };
        this.groups = [];
        this.globalVariable.push(new BehaviorSubject({ key: 'tgiData', value: [[], [], ''] }));
        this.globalVariable.push(new BehaviorSubject({ key: 'dropdownData', value: this.dropdownData }));
        this.globalVariable.push(new BehaviorSubject({ key: 'allGroups', value: this.groups }));
        this.globalVariable.push(new BehaviorSubject({ key: 'networks', value: [] }));
        this.globalVariable.push(new BehaviorSubject({ key: 'incorrectCombinationFormError', value: 'hide' }));
        this.globalVariable.push(new BehaviorSubject({ key: 'constraintErrorInTabGeneral', value: 'hide' }));
        this.globalVariable.push(new BehaviorSubject({ key: 'constraintErrorInTabDayOfWeek', value: 'hide' }));
        this.globalVariable.push(new BehaviorSubject({ key: 'constraintErrorInTabDateBased', value: 'hide' }));
        this.globalVariable.push(new BehaviorSubject({ key: 'constraintErrorInTabHalfHour', value: 'hide' }));
        this.globalVariable.push(new BehaviorSubject({ key: 'constraintErrorInTabBonusSpots', value: 'hide' }));
        this.globalVariable.push(new BehaviorSubject({ key: 'constraintErrorInTabTimeSeparationWindows', value: 'hide' }));
        this.globalVariable.push(new BehaviorSubject({ key: 'constraintErrorInTabInventoryAvailability', value: 'hide' }));
    }
    getGlobalVariable(returnKey) {
        let variable;
        variable = this.globalVariable.filter((member) => {
            return member.value.key === returnKey;
        });
        return variable[0].asObservable();
    }
    setItemOfGlobalVariable(key, val) {
        let variable;
        let index = 0;
        variable = this.globalVariable.filter((member) => {
            return member.value.key === key;
        });
        index = this.globalVariable.findIndex((item) => {
            return item.value.key === key;
        });
        if (variable) {
            this.globalVariable[variable[0].value.key] = variable[0].value.value;
        }
        else {
            this.globalVariable.push(variable);
        }
        this.globalVariable[index].next({ key, value: val });
    }
    getSharedVariable(returnKey) {
        return this.sharedVariables[returnKey];
    }
    setSharedVariable(key, value) {
        this.sharedVariables[key] = value;
    }
}
SharedVariableService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SharedVariableService_Factory() { return new SharedVariableService(); }, token: SharedVariableService, providedIn: "root" });
