<div class="modal-main card ux-card p-2">
  <h1>Ops...!</h1>
  <h3> We are sorry for inconvenience </h3>
  <p> Please call </p>
  <p> 1-678-640-9031 </p>
  <p> to complete your order</p>
  <h3> And your first month charges on us.</h3>
</div>
<button type="button"
        class="btn btn-warning btn-block"
        (click)="closeDialog('Purchase')">
  Return
</button>
