import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Amplify, Auth } from 'aws-amplify'
import awsconfig from './aws-exports';

if (environment.production) {
  enableProdMode();
}

Amplify.configure(awsconfig);

Auth.configure({
  userPoolId: 'us-east-1_E6yPURhtK',
  userPoolWebClientId: '6p0bauvbeb89tm5m6eprvpnjv7'
})

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
