import * as tslib_1 from "tslib";
import { stringify } from 'query-string';
import * as store from 'store';
import * as jwt_decode from 'jwt-decode';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../shared/shared-variable.service";
import * as i3 from "../api/api.service";
const { cognitoClientId, cognitoUrl } = environment;
export class AuthService {
    constructor(http, sharedVariableService, api) {
        this.http = http;
        this.sharedVariableService = sharedVariableService;
        this.api = api;
    }
    exchangeCodeForTokens(code) {
        const url = `${cognitoUrl}/oauth2/token`;
        const body = stringify({
            grant_type: 'authorization_code',
            client_id: cognitoClientId,
            code,
            redirect_uri: '${window.location.origin}/'
        });
        const options = {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        };
        return this.http.post(url, body, options).toPromise();
    }
    validateUser(token) {
        const url = '${cognitoUrl}/oauth2/token';
        const body = stringify({
            grant_type: 'refresh_token',
            client_id: cognitoClientId,
            refresh_token: token
        });
        const options = {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        };
        // return this.http.post(url, body, options).toPromise();
        return true;
    }
    setUserAttributes(idToken, access_token) {
        const decoded = jwt_decode(idToken);
        const isAdmin = decoded['cognito:groups'].includes('admin');
        this.sharedVariableService.setSharedVariable('isAdmin', isAdmin);
        this.sharedVariableService.setSharedVariable('userId', decoded.email);
    }
    getPreferences() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const params = { method: 'GET', url: 'user' };
            const result = yield this.api.callApi(params).toPromise();
            if (result.refreshNeeded) {
                // the user's admin status changed, so refresh their tokenss
                this.login();
            }
        });
    }
    login() {
        const qs = stringify({
            response_type: 'code',
            client_id: cognitoClientId,
            redirect_uri: '${window.location.origin}/',
            scope: ['openid']
        });
        const url = '${cognitoUrl}/oauth2/authorize?${qs}';
        console.log('-=-=-=-=-=> Should not be here');
        window.location.href = url;
    }
    getTokens(code) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.exchangeCodeForTokens(code);
                const { id_token, refresh_token, access_token } = response;
                this.accessToken = access_token;
                store.set('refresh_token', refresh_token);
                yield this.getPreferences();
                this.setUserAttributes(id_token, access_token);
                console.log('-=-=-=-=-=> Should not be here');
                return true;
            }
            catch (exception) {
                console.log('-=-=-=-=-=> Should not be here');
                return false;
            }
        });
    }
    checkAuth() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let refreshToken;
            try {
                refreshToken = store.get('refresh_token');
                if (refreshToken) {
                    const response = yield this.validateUser(refreshToken);
                    const { id_token, access_token } = response;
                    this.accessToken = access_token;
                    yield this.getPreferences();
                    this.setUserAttributes(id_token, access_token);
                    console.log('-=-=-=-=-=> Should not be here');
                    return true;
                }
                else {
                    console.log('-=-=-=-=-=> Should not be here');
                    return false;
                }
            }
            catch (exception) {
                console.log('-=-=-=-=-=> Should not be here');
                return false;
            }
        });
    }
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            store.remove('refresh_token');
            const { pathname } = window.location;
            store.set('pathname', pathname);
            const url = '${cognitoUrl}/logout';
            const options = {
                params: {
                    client_id: cognitoClientId,
                    logout_uri: 'https://error.dmlp-latam.com'
                },
                headers: {
                    'method': 'GET',
                    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                    'Access-Control-Allow-Origin': '*',
                }
            };
        });
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SharedVariableService), i0.ɵɵinject(i3.ApiService)); }, token: AuthService, providedIn: "root" });
