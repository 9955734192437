import { Injectable } from '@angular/core';
import { CartModel } from "./interfaces/cart-model";

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }
  currentCart: CartModel;


  selectedCart(cart) {
    this.currentCart = cart;
  }
}
