<div class="container-fluid">
  <!--footer -->
  <div class="row footer-background h-100">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <span class="footer-text align-tob">
        Copyright © 2019 - 2020 Golden Penguin Technology, LLC. All Rights Reserved.
      </span>
      <span class="footer-text align-bottom">
        Use of this Site is subject to express terms of use. By using this site, you signify that you agree to be bound by
        <a href="/termsAndServices" (click)="goToTermsAndServices()"> terms and condition </a>.
        Click <a href="/termsAndServices" (click)="goToTermsAndServices()"> here </a> to read detail.
      </span>
      <span class="footer-text align-bottom">
        For any question or concern please
        <a href="/contactUs" (click)="contactUs()"> Contact US</a>
      </span>
    </div>
  </div>
</div>
