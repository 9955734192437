import { MessageComponent } from '../../shared-components/message/message.component';
import { take } from 'rxjs/operators';
import { interval, Subject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "../api/api.service";
import * as i2 from "@angular/material/dialog";
export class ApiSignaturesService {
    constructor(apiService, dialog) {
        this.apiService = apiService;
        this.dialog = dialog;
        this.chargeCardOnceCalledRecently = false;
        this.getTheCurrentUserOnceCalledRecently = false;
        this.saveTransactionInfoCalledRecently = false;
        this.intervalTimer = interval(10000);
        this.subscription = this.intervalTimer.subscribe(() => {
            this.chargeCardOnceCalledRecently = false;
            this.getTheCurrentUserOnceCalledRecently = false;
            this.saveTransactionInfoCalledRecently = false;
        });
        this.chargeCardOnce = new Subject();
        this.getTheCurrentUser = new Subject();
        this.saveTransactionInfo = new Subject();
    }
    chargeCard(options) {
        if (!this.chargeCardOnceCalledRecently) {
            this.chargeCardOnceCalledRecently = true;
            const params = {
                url: 'purchase/one_time_card_charge',
                method: 'POST',
                body: options
            };
            this.apiService.callApi(params).pipe(take(1)).subscribe((result) => {
                this.chargeCardOnce.next(result);
            }, (error) => {
                this.dialog.open(MessageComponent, {
                    data: {
                        detail: 'error '
                    }
                });
            });
        }
    }
    callAndSaveTransactionInfo(options) {
        if (!this.saveTransactionInfoCalledRecently) {
            this.saveTransactionInfoCalledRecently = true;
            const params = {
                url: 'transaction/save_transaction_detail/',
                method: 'POST',
                body: options
            };
            this.apiService.callApi(params).pipe(take(1)).subscribe((result) => {
                this.saveTransactionInfo.next(result);
            }, (error) => {
                this.dialog.open(MessageComponent, {
                    data: {
                        detail: 'error '
                    }
                });
            });
        }
    }
    getTheUser() {
        if (!this.getTheCurrentUserOnceCalledRecently) {
            this.getTheCurrentUserOnceCalledRecently = true;
            const params = {
                url: 'user/get_user',
                method: 'GET',
            };
            this.apiService.callApi(params).pipe(take(1)).subscribe((result) => {
                this.getTheCurrentUser.next(result);
            }, (error) => {
                this.dialog.open(MessageComponent, {
                    data: {
                        detail: 'error '
                    }
                });
            });
        }
    }
}
ApiSignaturesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiSignaturesService_Factory() { return new ApiSignaturesService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.MatDialog)); }, token: ApiSignaturesService, providedIn: "root" });
