import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {

  @Input() numString: string;
  @Output() counterChange = new EventEmitter<string>();
  constructor() { }
  faPlus = faPlus;
  faMinus = faMinus;
  num = 1;
  ngOnInit() {
    this.num = Number(this.numString);
  }

  subtractUser() {
    this.num = Number(this.numString);
    if (this.num > 1) {
      this.num --
      this.numString = this.num.toString();
      this.counterChange.emit(this.numString)
    }
  }

  addUser() {
    this.num ++
    this.numString = this.num.toString();
    this.counterChange.emit(this.numString)
  }
}
