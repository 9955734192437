import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedVariableService {
  private globalVariable: BehaviorSubject<GlobalVariableInterface>[] = [];
  private sharedVariables: GlobalVariableInterface = {key: '', value: ''};
  dropdownData = {
    "income":["Low","Middle","High"],
    "gender":["All","Male","Female"],
    "cableSubscriber":["Total TV","Pay TV"],
    "householdRole":["All","Head of Household","Housewife"],
    "parentalStatus":["All","Has Children","Does Not Have Children"],
    "age":{"min":4,"max":99}
  }
  group = {
    groupName: 'Group 1',
    groupIndex: 0,
    operator: 'And',
    selectedLinks: [],
    isHidden: false
  };
  groups = [];
  constructor() {
    this.globalVariable.push(new BehaviorSubject<GlobalVariableInterface>({key: 'tgiData', value: [[], [], '']}));
    this.globalVariable.push(new BehaviorSubject<GlobalVariableInterface>({key: 'dropdownData', value: this.dropdownData}));
    this.globalVariable.push(new BehaviorSubject<GlobalVariableInterface>({key: 'allGroups', value: this.groups}));
    this.globalVariable.push(new BehaviorSubject<GlobalVariableInterface>({key: 'networks', value: []}));
    this.globalVariable.push(new BehaviorSubject<GlobalVariableInterface>({key: 'incorrectCombinationFormError', value: 'hide'}));
    this.globalVariable.push(new BehaviorSubject<GlobalVariableInterface>({key: 'constraintErrorInTabGeneral', value: 'hide'}));
    this.globalVariable.push(new BehaviorSubject<GlobalVariableInterface>({key: 'constraintErrorInTabDayOfWeek', value: 'hide'}));
    this.globalVariable.push(new BehaviorSubject<GlobalVariableInterface>({key: 'constraintErrorInTabDateBased', value: 'hide'}));
    this.globalVariable.push(new BehaviorSubject<GlobalVariableInterface>({key: 'constraintErrorInTabHalfHour', value: 'hide'}));
    this.globalVariable.push(new BehaviorSubject<GlobalVariableInterface>({key: 'constraintErrorInTabBonusSpots', value: 'hide'}));
    this.globalVariable.push(new BehaviorSubject<GlobalVariableInterface>({key: 'constraintErrorInTabTimeSeparationWindows', value: 'hide'}));
    this.globalVariable.push(new BehaviorSubject<GlobalVariableInterface>({key: 'constraintErrorInTabInventoryAvailability', value: 'hide'}));
  }

  getGlobalVariable(returnKey): Observable<GlobalVariableInterface> {
    let variable: any;
    variable = this.globalVariable.filter((member) => {
      return member.value.key === returnKey;
    });
    return variable[0].asObservable();
  }

  setItemOfGlobalVariable(key, val) {
    let variable: any;
    let index = 0;
    variable = this.globalVariable.filter((member) => {
      return member.value.key === key;
    });
    index = this.globalVariable.findIndex((item) => {
      return item.value.key === key;
    });
    if (variable) {
      this.globalVariable[variable[0].value.key] = variable[0].value.value;
    } else {
      this.globalVariable.push(variable);
    }
    this.globalVariable[index].next({key, value: val});
  }

  getSharedVariable(returnKey): any {
    return this.sharedVariables[returnKey];
  }

  setSharedVariable(key, value) {
    this.sharedVariables[key] = value;
  }
}

interface GlobalVariableInterface {
  key: any;
  value: any;
}
