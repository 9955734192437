import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { stringify } from 'query-string';

import * as store from 'store';
import * as jwt_decode from 'jwt-decode';
import { SharedVariableService } from '../shared/shared-variable.service';
import { ApiService } from '../api/api.service';
import { environment } from '../../../environments/environment';

const { cognitoClientId, cognitoUrl } = environment;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  accessToken: string;
  constructor(private http: HttpClient,
              private sharedVariableService: SharedVariableService,
              private api: ApiService) {}

  private exchangeCodeForTokens(code: string) {
    const url = `${cognitoUrl}/oauth2/token`;
    const body = stringify({
      grant_type: 'authorization_code',
      client_id: cognitoClientId,
      code,
      redirect_uri: '${window.location.origin}/'
  });
    const options = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    };
    return this.http.post(url, body, options).toPromise();
  }

  private validateUser(token: string) {
    const url = '${cognitoUrl}/oauth2/token';
    const body = stringify({
      grant_type: 'refresh_token',
      client_id: cognitoClientId,
      refresh_token: token
    });
    const options = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    };
    // return this.http.post(url, body, options).toPromise();
    return true;
  }

  private setUserAttributes(idToken: string, access_token: string) {
    const decoded = jwt_decode(idToken);
    const isAdmin = decoded['cognito:groups'].includes('admin');
    this.sharedVariableService.setSharedVariable('isAdmin', isAdmin);
    this.sharedVariableService.setSharedVariable('userId', decoded.email);
  }

  private async getPreferences() {
    const params = { method: 'GET', url: 'user'};
    const result = await this.api.callApi(params).toPromise();
    if (result.refreshNeeded) {
      // the user's admin status changed, so refresh their tokenss
      this.login()
    }
  }

  login() {
    const qs = stringify({
      response_type: 'code',
      client_id: cognitoClientId,
      redirect_uri: '${window.location.origin}/',
      scope: ['openid']
    });
    const url = '${cognitoUrl}/oauth2/authorize?${qs}';
    console.log('-=-=-=-=-=> Should not be here')
    window.location.href = url;
  }

  async getTokens(code: string): Promise<boolean> {
    try {
      const response: any = await this.exchangeCodeForTokens(code);
      const { id_token, refresh_token, access_token } = response;
      this.accessToken = access_token;
      store.set('refresh_token', refresh_token);
      await this.getPreferences();
      this.setUserAttributes(id_token, access_token);
      console.log('-=-=-=-=-=> Should not be here')
      return true;
    } catch (exception) {
      console.log('-=-=-=-=-=> Should not be here')
      return false;
    }
  }

  async checkAuth() {
    let refreshToken: string;
    try {
      refreshToken = store.get('refresh_token');
      if (refreshToken) {
        const response: any = await this.validateUser(refreshToken);
        const { id_token, access_token } = response;
        this.accessToken = access_token;
        await this.getPreferences();
        this.setUserAttributes(id_token, access_token);
        console.log('-=-=-=-=-=> Should not be here')
        return true;
      } else {
        console.log('-=-=-=-=-=> Should not be here')
        return false;
      }
    } catch (exception) {
      console.log('-=-=-=-=-=> Should not be here')
      return false;
    }
  }

  async logout() {
    store.remove('refresh_token');
    const { pathname } = window.location;
    store.set('pathname', pathname);

    const url = '${cognitoUrl}/logout';
    const options = {
      params: {
        client_id: cognitoClientId,
        logout_uri: 'https://error.dmlp-latam.com'
      },
      headers: {
        'method': 'GET',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Origin': '*',

      }
    };
  }
}
