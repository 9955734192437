<div class="">
  <div class="p-5">
    <button type="button"
            class="btn btn-warning btn-block"
            (click)="closeDialog()">
      Return
    </button>
  </div>

  <div class="card us-card p-5">
    <div class="card us-card p-5">
      <h2>Phone:</h2>
      <h6 class="pl-5">
        1-678-640-9031
      </h6>
    </div>

    <div class="card us-card p-5">
      <h2>email:</h2>
      <h6 class="pl-5">
        info@goldenPenguinTech.com
      </h6>

    </div>

    <div class="card us-card p-5">
      <h2>Address:</h2>
      <h6 class="pl-5">
        2483 Arbor Walk CT,
      </h6>
      <h6 class="pl-5">
        Acworth, GA, 30101,
      </h6>
      <h6 class="pl-5">
        United State of America
      </h6>
    </div>
  </div>

  <div class="p-5">
    <button type="button"
            class="btn btn-warning btn-block"
            (click)="closeDialog()">
      Return
    </button>
  </div>
</div>
