<div class="container-fluid">
  <!--Top section-->
  <div class="row">
    <img id="firstPageBg" src="../../assets/images/phone-hor-3.png" alt="imageOfEmail">
    <div class="top-box-left col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="top-box-small-text">
        Start your professional email immediately,
      </div>
      <div class="top-box-first-text">
        Get a professional email
        and show the world
        you are established
      </div>
      <br>
      <span class="top-box-second-text">
        <ul>
          <li>A domain based email (yourname@yourCompanyName.com)</li>
          <li>Get your emails in any mailbox (such as Outlook, Google or yahoo)</li>
          <li>Sync your emails across all devices</li>
          <li>Include spam filter</li>
          <li>Email backup and email archiving is available</li>
        </ul>
      </span>
    </div>
    <div class="top-box-left col-lg-6 col-md-6 col-sm-12 col-xs-12">
    </div>
  </div>
</div>

<!--pricing section-->
<div class="container-fluid Second-section">
  <div class="row">
    <div class="col">
      <div class="card card-body mb-2 h-100">
        <div class="price-box-margin">
          <h2>Individual Plan</h2>
          <span>One Professional Email</span>
        </div>
        <span>2 GB email storage</span>
        <span><span class="large-number">$1.99</span> / month</span>
        <span class="badge badge-light align-content-start">4.99  / month after you renew</span>
        <div class="button-padding">
          <button type="button" class="btn btn-warning btn-block" (click)="addToCart('Individual')">Add to Cart</button>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card card-body mb-2 h-100">
        <div class="price-box-margin">
          <h2>Basic Plan</h2>
          <span>Up to 3 Professional Emails</span>
        </div>
        <span>10 GB email storage</span>
        <span><span class="large-number">$4.99</span> / month</span>
        <span class="badge badge-light align-content-start">12.99  / month after you renew</span>
        <div class="button-padding">
          <button type="button" class="btn btn-warning btn-block" (click)="addToCart('Basic')">Add to Cart</button>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card card-body mb-2 h-100">
        <div class="price-box-margin">
          <h2>Business Plan</h2>
          <span>Up to 10 Professional Emails</span>
        </div>
        <span>50 GB email storage</span>
        <span><span class="large-number">$14.99</span> / month</span>
        <span class="badge badge-light align-content-start">39.99 / month after you renew</span>
        <div class="button-padding">
          <button type="button" class="btn btn-warning btn-block" (click)="addToCart('Business')">Add to Cart</button>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card card-body mb-2 h-100">
        <div class="price-box-margin">
          <h2>Premium Plan</h2>
          <span>10 or more Professional Emails</span>
        </div>
        <span>100 GB email storage</span>
        <span><span class="large-number">$0.99</span> per user / month</span>
        <span class="badge badge-light align-content-start">3.99 per user / month after you renew</span>
        <div class="button-padding">
          <button type="button" class="btn btn-warning btn-block" (click)="addToCart('Premium')">Add to Cart</button>
        </div>
    </div>
  </div>
  </div>
</div>

<!--Cart-->
<div class="container-fluid  cart-section" *ngIf="cart">

</div>
