<div class="container-fluid Second-section">
  <div class="row cart-main-page">
    <div class="col-8">
      <div class="card ux-card">
        <div>
          <span class="title-h1">{{ cart.mainPlan }} Plan</span>
        </div>
        <div class="mt-5">
          <span class="float-right"
                [class.strikeText]="discountedPriceSelected">
            {{ cart.originalPrice | currency }} / month
          </span>
          <div class="custom-control custom-radio">
            <input type="radio"
                   value="originalPrice"
                   class="custom-control-input"
                   id="originalPrice"
                   name="originalPrice"
                   (click)="termSelection('originalPrice')">
            <label class="custom-control-label" for="originalPrice">1 Month</label>
          </div>
          <hr>
        </div>
        <div>
          <div>
            <span class="float-right "
                  [class.strikeText]="originalPriceSelected">
              {{ cart.discountedPrice | currency }} / month
            </span>
            <span class="float-right discount-text"
                  [class.strikeText]="originalPriceSelected">
              You Save {{ cart.discountedPrice/cart.originalPrice | percent}}
            </span>
          </div>
          <div class="custom-control custom-radio">
            <input type="radio"
                   value="discountedPrice"
                   class="custom-control-input"
                   id="price"
                   name="originalPrice"
                   (click)="termSelection('discountedPrice')"
                   checked>
            <label class="custom-control-label" for="price">12 Month</label>
          </div>
        </div>
        <div>
          <div class="mt-5">
            <span>
                Number of professional emails:
            </span>
            <span class="float-right">
              {{ cart.numberOfEmailAddresses }}
            </span>
          </div>
          <div class="w-25 mt-3">
            <app-counter [numString]="cart.numberOfEmailAddresses" (counterChange)="numberOfUserChanged($event)"></app-counter>
          </div>
        </div>
      </div>
      <div class="card ux-card mt-3">
        <form [formGroup]="form">
          <h6>Domain:</h6>
          <mat-form-field>
            <mat-label>Domain</mat-label>
            <input matInput
                   formControlName="domain"
                   placeholder="your-company-name.com"
                   [(ngModel)]='domain' >
          </mat-form-field>
          <h6>Email addresses:</h6>
          <div formArrayName="emails" *ngFor="let ctrl of form['controls'].emails['controls']; let i = index">
            <ng-container [formGroupName]="i">
              <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput formControlName="email"><span [hidden]="!domain" class="domain-ext float-right">{{'@' + domain}}</span>
              </mat-form-field>
            </ng-container>
          </div>
        </form>
      </div>
    </div>

    <div class="col-4">
      <div class="card ux-card">
        <div>
          <span class="title-h2">Order Summary:</span>
        </div>
        <div>
          <span class="">{{ cart.mainPlan }} Plan</span>
          <span class="float-right">{{ cart.total | currency }}</span>
        </div>
        <div class="pt-5">
          <button type="button" class="btn btn-warning btn-block" (click)="goToCheckoutPage()">Checkout</button>
        </div>
        <div class="pt-3">
          <button type="button" class="btn btn-cancel btn-block" (click)="goToFirstPage()">Return</button>
        </div>
      </div>
    </div>
  </div>
</div>
