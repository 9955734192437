import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material";

@Component({
  selector: 'app-cart-modal',
  templateUrl: './cart-modal.component.html',
  styleUrls: ['./cart-modal.component.scss']
})
export class CartModalComponent implements OnInit {

  public form: FormGroup;
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  phoneNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  creditCardNumber: string;
  expDate: string;
  csvNumber: string;

  constructor(private fb: FormBuilder,
              public dialogRef: MatDialogRef<CartModalComponent>) {
    this.form = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(1)]],
      lastName: ['', [Validators.required, Validators.minLength(1)]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.minLength(10)]],
      confirmEmail: ['', [Validators.required, Validators.email]],
      address1: ['', [Validators.required, Validators.minLength(1)]],
      address2: [''],
      city: ['', [Validators.required, Validators.minLength(1)]],
      state: ['', [Validators.required, Validators.minLength(2)]],
      country: ['', [Validators.required, Validators.minLength(2)]],
      zipCode: ['', [Validators.required, Validators.minLength(5)]],
      creditCardNumber: ['', [Validators.required, Validators.minLength(5)]],
      expDate: ['', [Validators.required, Validators.minLength(5)]],
      csvNumber: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  ngOnInit() {
  }

  getEmailErrorMessage() {
    if (this.form.controls.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.form.controls.email.hasError('email') ? 'Not a valid email' : '';
  }

  getConfirmEmailErrorMessage() {
    if (this.form.controls.confirmEmail.hasError('required')) {
      return 'You must enter a value';
    }

    return this.form.controls.confirmEmail.hasError('email') ? 'Not a valid email' : '';
  }

  closeDialog(value) {
    let data = {
      'form': this.form.controls,
      'status': value
    }
    this.dialogRef.close();
    /*Object.values(this.form.controls).forEach((control) => {
      control.markAsTouched();
    });

    if (this.form.invalid && value !== 'Cancel') {
      return this.form.markAsTouched()
    }*/
  }

}
