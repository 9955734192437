/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./counter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./counter.component";
var styles_CounterComponent = [i0.styles];
var RenderType_CounterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CounterComponent, data: {} });
export { RenderType_CounterComponent as RenderType_CounterComponent };
export function View_CounterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "input-group-prepend"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", "btn btn-dark btn-sm"], ["id", "minus-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.subtractUser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(5, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "input", [["class", "form-control form-control-sm"], ["id", "qty_input"], ["min", "1"], ["type", "number"]], [[8, "value", 0]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "input-group-prepend"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "btn btn-dark btn-sm"], ["id", "plus-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addUser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(10, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.faMinus; _ck(_v, 5, 0, currVal_2); var currVal_6 = _co.faPlus; _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 5).title; var currVal_1 = i1.ɵnov(_v, 5).renderedIconHTML; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.num, ""); _ck(_v, 6, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 10).title; var currVal_5 = i1.ɵnov(_v, 10).renderedIconHTML; _ck(_v, 9, 0, currVal_4, currVal_5); }); }
export function View_CounterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-counter", [], null, null, null, View_CounterComponent_0, RenderType_CounterComponent)), i1.ɵdid(1, 114688, null, 0, i5.CounterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CounterComponentNgFactory = i1.ɵccf("app-counter", i5.CounterComponent, View_CounterComponent_Host_0, { numString: "numString" }, { counterChange: "counterChange" }, []);
export { CounterComponentNgFactory as CounterComponentNgFactory };
