import { Component, OnInit } from '@angular/core';
import { CommonService } from "../common.service";
import { CartModel } from "../interfaces/cart-model";
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { Router } from "@angular/router";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  public form: FormGroup;
  domain = '';
  emails = '';
  cart: CartModel;
  discountedPriceSelected = true;
  originalPriceSelected = false;
  formArray = this.fb.array([this.fb.control('')]);

  constructor(private commonService: CommonService,
              private fb: FormBuilder,
              private router: Router) {

    this.form = this.fb.group({
      emails: this.formArray,
      domain: ['']
    });
  }

  ngOnInit() {
    this.cart = this.commonService.currentCart;

    // this if cause page keep same data on refresh
    if (this.cart) {
      localStorage.setItem('Cart', JSON.stringify(this.cart));
    } else {
      this.cart = JSON.parse(localStorage.getItem('Cart'));
    }
    this.calculateTotal();
  }

  createItem() {
    this.formArray.push(
      this.fb.group({email: new FormControl('')})
    );
  }

  clearFormArray(){
    while (this.formArray.length !== 0) {
      this.formArray.removeAt(0);
    }
  };

  // generate input fields dynamically for email addresses
  addEmailsInput(numberOfEmails) {
    this.clearFormArray();
    for (let i = 0; i < numberOfEmails; i++) {
      this.createItem();
    }
  }

  numberOfUserChanged(event) {
    this.cart.numberOfEmailAddresses = event;
    this.addEmailsInput(this.cart.numberOfEmailAddresses);
  }

  termSelection(plan) {
    if (plan === 'discountedPrice') {
      this.discountedPriceSelected = true;
      this.originalPriceSelected = false;
      this.cart.selectedTerm = 'discountedPrice'
    } else {
      this.discountedPriceSelected = false;
      this.originalPriceSelected = true;
      this.cart.selectedTerm = 'originalPrice'
    }
    this.calculateTotal();
  }

  calculateTotal() {
    if (!this.cart.total) { this.cart.total = 0; }
    if (this.cart.selectedTerm === 'originalPrice') {
      this.cart.total = this.cart.originalPrice;
    } else {
      this.cart.total = this.cart.discountedPrice * 12;
      localStorage.setItem('CartTotal', this.cart.total.toString());
    }
    this.addEmailsInput(this.cart.numberOfEmailAddresses);
  }

  goToFirstPage() {
    this.router.navigateByUrl('/firstPage');
  }

  goToCheckoutPage() {
    this.router.navigateByUrl('/checkout');
  }
}
